(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Initial app
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
const sf_app = angular.module('sf_app', [
  'ui.router',
  'oc.lazyLoad',
  'ncy-angular-breadcrumb',
  'angular-loading-bar',
  'ngStorage',
  'ngFlash',
  'ngAnimate',
  'ngRoute',
  'ngSanitize',
  'ngCsv',
  'app.conf'
]);
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Config & Routing
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
sf_app.config(['cfpLoadingBarProvider', function(cfpLoadingBarProvider) {
  cfpLoadingBarProvider.includeSpinner = false;
  cfpLoadingBarProvider.latencyThreshold = 1;
}]);
sf_app.config(['$stateProvider', '$urlRouterProvider', '$ocLazyLoadProvider', '$breadcrumbProvider', function($stateProvider, $urlRouterProvider, $ocLazyLoadProvider, $breadcrumbProvider) {
  // breadcrumbProvider
  $breadcrumbProvider.setOptions({
    prefixStateName: 'sf_app',
    includeAbstract: true,
    template: '<li class="breadcrumb-item" ng-repeat="step in steps" ng-class="{active: $last}" ng-switch="$last || !!step.abstract"><a ng-switch-when="false" href="{{step.ncyBreadcrumbLink}}">{{step.ncyBreadcrumbLabel}}</a><span ng-switch-when="true">{{step.ncyBreadcrumbLabel}}</span></li>'
  });
  //===== Admin Layout =====//
  $stateProvider.state('sf_app',{
    abstract: true,
    templateUrl: 'views/layouts/full.html',
    ncyBreadcrumb: {
      label: 'Root',
      skip: true
    },
    resolve: {
      loadCSS: ['$ocLazyLoad', function($ocLazyLoad) {
        return $ocLazyLoad.load([{
          serie: true,
          name: 'Flags',
          files: ['vendors/css/flag-icon.min.css']
        },{
          serie: true,
          name: 'Font Awesome',
          files: ['vendors/css/font-awesome.min.css']
        },{
          serie: true,
          name: 'Simple Line Icons',
          files: ['vendors/css/simple-line-icons.min.css']
        }]);
      }]
    }
  });
  //===== Simple Layout =====//
  $stateProvider.state('appSimple', {
    abstract: true,
    templateUrl: 'views/layouts/simple.html',
    resolve: {
      loadCSS: ['$ocLazyLoad', function($ocLazyLoad) {
        return $ocLazyLoad.load([{
          serie: true,
          name: 'Font Awesome',
          files: ['vendors/css/font-awesome.min.css']
        },{
          serie: true,
          name: 'Simple Line Icons',
          files: ['vendors/css/simple-line-icons.min.css']
        }]);
      }],
    }
  });
  //===== Error Page =====//
  $stateProvider.state('sf_app.error',{
    url: '/error',
    templateUrl: 'views/error.html',
    ncyBreadcrumb: {
      label: 'エラー'
    }
  });
  //===== Error Page =====//
  $stateProvider.state('sf_app.unauthorized',{
    url: '/unauthorized',
    templateUrl: 'views/unauthorized.html',
    ncyBreadcrumb: {
      label: 'エラー'
    }
  });
  //===== Transaction Page =====//
  $stateProvider.state('sf_app.transaction', {
    url: '/transaction',
    template: '<ui-view></ui-view>',
    abstract: true,
    ncyBreadcrumb: {
      label: '支払情報'
    }
  });
  $stateProvider.state('sf_app.transaction.list', {
    url: '/',
    templateUrl: 'views/transaction.html',
    ncyBreadcrumb: {
      label: '支払情報一覧'
    }
  });
  //===== Member Page =====//
  $stateProvider.state('sf_app.member', {
    url: '/member',
    template: '<ui-view></ui-view>',
    abstract: true,
    ncyBreadcrumb: {
      label: '会員情報'
    }
  });
  $stateProvider.state('sf_app.member.list', {
    url: '/',
    templateUrl: 'views/member.html',
    ncyBreadcrumb: {
      label: '会員情報一覧'
    }
  });
  //===== InOut Page =====//
  $stateProvider.state('sf_app.inout', {
    url: '/inout',
    template: '<ui-view></ui-view>',
    abstract: true,
    ncyBreadcrumb: {
      label: '入退館履歴管理'
    }
  });
  $stateProvider.state('sf_app.inout.list', {
    url: '/',
    templateUrl: 'views/inout.html',
    ncyBreadcrumb: {
      label: '入退館履歴一覧'
    }
  });
  //===== Notification Page =====//
  $stateProvider.state('sf_app.notification', {
    url: '/notification',
    template: '<ui-view></ui-view>',
    abstract: true,
    ncyBreadcrumb: {
      label: '諸届'
    }
  });
  $stateProvider.state('sf_app.notification.list', {
    url: '/',
    templateUrl: 'views/notification.html',
    ncyBreadcrumb: {
      label: '諸届一覧'
    }
  });
  $stateProvider.state('sf_app.notification.plan', {
    url: '/plan',
    templateUrl: 'views/notificationPlan.html',
    ncyBreadcrumb: {
      label: '区分コース'
    }
  });
  $stateProvider.state('sf_app.notification.payment', {
    url: '/payment',
    templateUrl: 'views/notificationPayment.html',
    ncyBreadcrumb: {
      label: '支払方法'
    }
  });
  // $stateProvider.state('sf_app.notification.locker', {
  //   url: '/locker',
  //   templateUrl: 'views/notificationLocker.html',
  //   ncyBreadcrumb: {
  //     label: 'ロッカー'
  //   }
  // });
  //===== Personal Page =====//
  $stateProvider.state('sf_app.personal', {
    url: '/personal',
    template: '<ui-view></ui-view>',
    abstract: true,
    ncyBreadcrumb: {
      label: 'パーソナルログ'
    }
  });
  $stateProvider.state('sf_app.personal.top', {
    url: '/',
    templateUrl: 'views/personal.html',
    ncyBreadcrumb: {
      label: 'メニュー'
    }
  });
  $stateProvider.state('sf_app.counsel', {
    url: '/counsel',
    template: '<ui-view></ui-view>',
    abstract: true,
    ncyBreadcrumb: {
      label: 'カウンセリング'
    }
  });
  $stateProvider.state('sf_app.counsel.list', {
    url: '/',
    templateUrl: 'views/counsel.html',
    ncyBreadcrumb: {
      label: 'カウンセリング一覧'
    }
  });
  $stateProvider.state('sf_app.counsel.item', {
    url: '/:counsel_id',
    templateUrl: 'views/counsel-item.html',
    ncyBreadcrumb: {
      label: 'カウンセリングアイテム'
    }
  });
  $stateProvider.state('sf_app.counsel.photo', {
    url: '/photo',
    templateUrl: 'views/counsel-photo.html',
    ncyBreadcrumb: {
      label: 'カウンセリング写真'
    }
  });
  $stateProvider.state('sf_app.program', {
    url: '/program',
    template: '<ui-view></ui-view>',
    abstract: true,
    ncyBreadcrumb: {
      label: 'トレーニングプログラム'
    }
  });
  $stateProvider.state('sf_app.program.top', {
    url: '/',
    templateUrl: 'views/program-top.html',
    ncyBreadcrumb: {
      label: 'トレーニングプログラムトップ'
    }
  });
  $stateProvider.state('sf_app.program.list', {
    url: '/:level',
    templateUrl: 'views/program.html',
    ncyBreadcrumb: {
      label: 'トレーニングプログラム一覧'
    }
  });
  $stateProvider.state('sf_app.program.item', {
    url: '/item/:uploaded_pdf',
    templateUrl: 'views/program-item.html',
    ncyBreadcrumb: {
      label: 'トレーニングプログラムアイテム'
    }
  });

  //===== Top Page =====//
  $stateProvider.state('sf_app.top', {
    url: '/top/',
    templateUrl: 'views/top.html',
  });
  //===== Login Page =====//
  $stateProvider.state('appSimple.login',{
    url: '/login',
    templateUrl: 'views/login.html'
  });
  //===== Password Reset Page =====//
  $stateProvider.state('appSimple.resetPassword',{
    url: '/reset-password',
    templateUrl: 'views/reset-password.html'
  });
  $stateProvider.state('appSimple.resetPasswordEdit',{
    url: '/reset-password/:token',
    templateUrl: 'views/reset-password-edit.html'
  });
  //===== Default Page =====//
  $urlRouterProvider.otherwise('/login');
}]);


// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Authentication
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
sf_app.run(['$rootScope', '$http', '$location', '$sessionStorage', '$state', '$anchorScroll',
  function run($rootScope, $http, $location, $sessionStorage, $state, $anchorScroll) {
  // keep member logged in after page refresh
  if ($sessionStorage.currentMember) {
    $http.defaults.headers.common.Authorization = 'Bearer ' + $sessionStorage.currentMember.token;
    $http.defaults.headers.common['Content-Type'] = 'application/json';
  }
  // redirect to login page if not logged in and trying to access a restricted page
  $rootScope.$on('$locationChangeStart', function (event, next, current) {
    var publicPages = ['/login', '/reset-password'];
    var restrictedPage = !publicPages.some(v => $location.path().indexOf(v) === 0);
    if (restrictedPage && !$sessionStorage.currentMember) {
      $location.path('/login');
    }
  });
  $rootScope.$on('$locationChangeSuccess',function(){
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    $anchorScroll();
  });
  $rootScope.$state = $state;
}]);
//===== Reset Password =====//
sf_app.controller('ResetPasswordCtl', ['$scope', '$location', '$http', 'apiBaseUrl', 'http_config', function($scope, $location, $http, apiBaseUrl, http_config) {
  const url = apiBaseUrl + '/member/reset/email';
  $scope.sendMail = function () {
    $scope.json = {
      'email': $scope.member.email,
      'birthday': `${$scope.member.birthday.getFullYear()}-` +
          `${('0' + ($scope.member.birthday.getMonth() + 1)).slice(-2)}-` +
          `${('0' + ($scope.member.birthday.getDate())).slice(-2)}`,
    };
    $http.post(url, $scope.json, http_config).then(function(response) {
      $location.path('/login');
    });
  };
}]);
sf_app.controller('PasswordEditCtl', ['$scope', '$location', '$http', '$stateParams', 'Flash', 'apiBaseUrl', 'http_config', function($scope, $location, $http, $stateParams, Flash, apiBaseUrl, http_config) {
  $scope.data = {};
  const token = $stateParams.token;
  const tokenUrl = apiBaseUrl + `/member/reset/${token}`;
  $http.get(tokenUrl, http_config).then(function(response) {
    if(!response.data?.result) {
      Flash.create('warning', '有効なURLではありません。再度手続きを行ってください。', 30000, true);
      $location.path('/reset-password');
    }
    $scope.data.token = token;
  });

  const resetUrl = apiBaseUrl + '/member/reset';
  $scope.resetNow = function () {
    $http.post(resetUrl, $scope.data, http_config).then(function(response) {
      if(response.data?.result) {
        Flash.create('success', 'パスワードを更新しました。', 30000, true);
      } else {
        Flash.create('warning', 'パスワードの更新に失敗しました。再度手続きを行ってください。', 30000, true);
      }
      $location.path('/login');
    }).catch(e => {
      Flash.create('warning', 'パスワードの更新に失敗しました。再度手続きを行ってください。', 30000, true);
      $location.path('/login');
    });
  };
}]);
//===== Member Login =====//
sf_app.controller('LoginCtl', ['$scope', '$location', '$http', '$sessionStorage', 'apiBaseUrl', 'http_config', function($scope, $location, $http, $sessionStorage, apiBaseUrl, http_config){
  var url = apiBaseUrl + '/member/auth';
  $scope.$storage = $sessionStorage;
  $scope.login = function(){
    $scope.loginError = false;
    $scope.json = {
      'loginId': $scope.member.loginId,
      'password': $scope.member.password
    };
    $http.post(url, $scope.json, http_config).then(function(response) {
      if(response.status == 200 && response.data.success == true){
        $scope.$storage.currentMember = response.data.result;
        $http.defaults.headers.common.Authorization = 'Bearer ' + response.data.result.token;
        $http.defaults.headers.common['Content-Type'] = 'application/json';
        $location.path('/top/');
      } else {
        $scope.loginError = true;
      }
    });
  }
}]);
//===== Member Logout =====//
sf_app.controller('NavCtl', ['$scope', '$location', '$http', '$sessionStorage', function($scope, $location, $http, $sessionStorage) {
  $scope.logout = function(){
    delete $sessionStorage.currentMember;
    $http.defaults.headers.common.Authorization = '';
    $location.path('/login');
  };
}]);


// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Menu Bar
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
sf_app.controller('SidebarNavCtl', ['$scope', '$sessionStorage', 'MemberFactory', function($scope, $sessionStorage, MemberFactory) {
  MemberFactory.getOne('self').then(function(member) {
    $scope.studio = member.studio;
  });
  $scope.$storage = $sessionStorage;
  // セッション情報をクリアする
  $scope.sessionClear = function () {
    $.each(sessionStorage, function(index, value){
      if (index !== 'ngStorage-currentUser') {
        sessionStorage.removeItem(index);
      }
    })
  }
}]);


// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
// File upload
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++ 
sf_app.directive('fileModel', ['$parse', function($parse) {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      const model = $parse(attrs.fileModel)
      element.bind('change', function() {
        scope.$apply(() => {
          model.assign(scope, element[0].files[0])
        })
      })
    },
  }
}]);
sf_app.directive('filesModel', ['$parse', function($parse) {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      const model = $parse(attrs.filesModel)
      element.bind('change', function() {
        scope.$apply(() => {
          model.assign(scope, element[0].files)
        })
      })
    },
  }
}]);
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Secure image view
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++ 
sf_app.directive('httpSrc', ['$http', function ($http) {
  return {
    // do not share scope with sibling img tags and parent
    // (prevent show same images on img tag)
    scope: {
      httpSrc: '@'
    },
    link: function ($scope, elem, attrs) {
      function revokeObjectURL() {
        if ($scope.objectURL) {
          URL.revokeObjectURL($scope.objectURL);
        }
      }
      $scope.$watch('objectURL', function (objectURL) {
        elem.attr('src', objectURL);
      });
      $scope.$on('$destroy', function () {
        revokeObjectURL();
      });
      attrs.$observe('httpSrc', function (url) {
        revokeObjectURL();
        if (url && url.indexOf('data:') === 0) {
          $scope.objectURL = url;
        } else if (url) {
          $http.get(url, {
            responseType: 'arraybuffer',
            cache: true,
            headers: {
              'accept': 'image/webp,image/*,*/*;q=0.8'
            }
          }).then(function (response) {
            var blob = new Blob(
              [response.data], { type: response.headers('Content-Type') }
            );
            $scope.objectURL = URL.createObjectURL(blob);
          });
        }
      });
    }
  };
}]);

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Input currency
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++ 
// This alllows you to format a text input field.
// <input type="text" ng-model="test" format="number" />
// <input type="text" ng-model="test" format="currency" />
sf_app.directive('format', ['$filter', function ($filter) {
  return {
    require: 'ngModel',
    link: function (scope, elem, attrs, ctrl) {
      if (!ctrl) return;
      ctrl.$formatters.unshift(function (a) {
        if (attrs.format == 'number') {
          //return $filter(attrs.format)(ctrl.$modelValue, 0);
          return parseInt(ctrl.$modelValue);
        } else if (attrs.format == 'currency') {
          return $filter(attrs.format)(ctrl.$modelValue, '¥', 0);
        } else {
          return $filter(attrs.format)(ctrl.$modelValue);
        }
      });
      // Disable followings not to update input values format automatically
      //elem.bind('blur', function(event) {
      //  var plainNumber = elem.val().replace(/[^\d|\-+|\.+]/g, '');
      //  elem.val($filter(attrs.format)(plainNumber));
      //});
    }
  };
}]);

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Input validation
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++ 
sf_app.directive('numericOnly', function () {
  return {
    require: 'ngModel',
    link: function (scope, element, attr, ngModelCtrl) {
      function fromFormInput(text) {
        if (text) {
          var transformed_input = text.replace(/[^0-9]/g, '');
          if (transformed_input !== text) {
            ngModelCtrl.$setViewValue(transformed_input);
            ngModelCtrl.$render();
          }
          return transformed_input;
        }
        return undefined;
      }            
      ngModelCtrl.$parsers.push(fromFormInput);
    }
  };
});
sf_app.directive('alphabetOnly', function () {
  return {
    require: 'ngModel',
    link: function (scope, element, attr, ngModelCtrl) {
      function fromFormInput(text) {
        if (text) {
          var transformed_input = text.replace(/[^A-Z]*$/g, '');
          if (transformed_input !== text) {
            ngModelCtrl.$setViewValue(transformed_input);
            ngModelCtrl.$render();
          }
          return transformed_input;
        }
        return undefined;
      }            
      ngModelCtrl.$parsers.push(fromFormInput);
    }
  };
});

},{}],2:[function(require,module,exports){
'use strict';

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Counsel Management Controller
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++

angular
.module('sf_app')
.controller('CounselMgmtCtl', CounselMgmtCtl)
.controller('CounselItemCtl', CounselItemCtl);

CounselMgmtCtl.$inject = ['$scope', '$state', '$location', '$http', '$filter', '$stateParams', 'Flash', 'MemberFactory', 'apiBaseUrl'];
function CounselMgmtCtl($scope, $state, $location, $http, $filter, $stateParams, Flash, MemberFactory, apiBaseUrl) {
  //----- Set Init Params:
  $scope.member = [];
  $scope.items = [];
  $scope.select = {};
  $scope.pageLimit = 100;
  $scope.limitBegin = 0;
  $scope.searching = true;
  $scope.showList = true;
  $scope.showTable = false;
  $scope.showFilterOption = false;
  $scope.lineGraphOptions = getLineGraphOptions();
  $scope.graph1 = undefined;
  $scope.graph2 = undefined;
  //----- Click Pager:
  $scope.pagerClick = function (num) {
    $scope.limitBegin = num * $scope.pageLimit;
  };
  //----- Return Pager Array:
  $scope.pagerArr = function(num) {
    num = Math.ceil(num);
    let array = [];
    for(let i = 0; i < num; i++) {
      array[i] = i;
    }
    return array;
  };
  MemberFactory.getOne('self').then(function(data) {
    $scope.member = data;
  });

  //----- List counsel:
  MemberFactory.getCounsel('self').then(function(data) {
    $scope.searching = false;
    $scope.items = data;
    if(data.length === 0) {
      $scope.showTable = false;
      $scope.showFilterOption = false;
    } else {
      $scope.startImageNum = data.length - 1;
      $scope.startImageSrc1 = apiBaseUrl + '/counsel/' + data[$scope.startImageNum].id + '/picture/' + data[$scope.startImageNum].uploadedImg1;
      $scope.startImageSrc2 = apiBaseUrl + '/counsel/' + data[$scope.startImageNum].id + '/picture/' + data[$scope.startImageNum].uploadedImg2;
      $scope.startImageSrc3 = apiBaseUrl + '/counsel/' + data[$scope.startImageNum].id + '/picture/' + data[$scope.startImageNum].uploadedImg3;
      $scope.startImageSrc4 = apiBaseUrl + '/counsel/' + data[$scope.startImageNum].id + '/picture/' + data[$scope.startImageNum].uploadedImg4;
      $scope.imageSrc1 = apiBaseUrl + '/counsel/' + data[0].id + '/picture/' + data[0].uploadedImg1;
      $scope.imageSrc2 = apiBaseUrl + '/counsel/' + data[0].id + '/picture/' + data[0].uploadedImg2;
      $scope.imageSrc3 = apiBaseUrl + '/counsel/' + data[0].id + '/picture/' + data[0].uploadedImg3;
      $scope.imageSrc4 = apiBaseUrl + '/counsel/' + data[0].id + '/picture/' + data[0].uploadedImg4;
      $scope.showTable = true;
      $scope.showFilterOption = true;
    }
  });

  $scope.updateGraph = function() {
    if($scope.graph1) {
      $scope.graph1.destroy();
    }
    if($scope.graph2) {
      $scope.graph2.destroy();
    }
    if($scope.select.lineGraph) {
      let ctx = document.getElementById('myChart').getContext('2d');
      let ctxModal = document.getElementById('myChartModal').getContext('2d');
      ctx.canvas.height = 300;
      ctx.canvas.width = $('.canvas-container').width();
      ctxModal.canvas.height = 300;
      ctxModal.canvas.width = $('.canvas-container-modal').width();
      $scope.graph1 = new Chart(ctx, createGraphData());
      $scope.graph2 = new Chart(ctxModal, createGraphData());
    }
  };

  function createGraphData() {
    let suggestedMin = 0, suggestedMax = 0;
    if($scope.items.length > 0) {
      let graphValues = $scope.items.map(v => {return parseInt(v[$scope.select.lineGraph.column]);}).filter(v => !isNaN(v));
      suggestedMin = Math.min.apply(null, graphValues) - 5;
      suggestedMax = Math.max.apply(null, graphValues) + 5;
    }
    return {
      type: 'line',
      data: {
        labels: getGraphLabels(),
        datasets: [{
          label: $scope.select.lineGraph ? $scope.select.lineGraph.label : null,
          data: getGraphData(),
          borderColor : "rgba(230,26,123,1)",
          backgroundColor : "rgba(230,26,123,1)",
          lineTension: 0,
          fill: false,
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            type: 'linear',
            ticks: {
              suggestedMin: suggestedMin,
              suggestedMax: suggestedMax,
              stepSize: parseInt((suggestedMax - suggestedMin) / 7)
            }
          }]
        },
      }
    };
  }

  function getGraphLabels() {
    let labels = [];
    if($scope.items.length > 0) {
      labels = $scope.items.map(function (row) {
        return [convertJSTString(row['counseledAt'])]
      }).reduce(function (a, b) {
        return a.concat(b);
      });
      labels.reverse();
    }
    let fill = Array(14 - labels.length).join(',').split(',').map(function(){ return ' '; });

    return labels.concat(fill);
  }

  function getGraphData() {
    let data = [];
    if($scope.items.length > 0) {
      data = $scope.items.map(function (row) {
        return [row[$scope.select.lineGraph.column]]
      }).reduce(function (a, b) {
        return a.concat(b);
      });
      data.reverse();
    }
    return data;
  }
}


CounselItemCtl.$inject = ['$scope', '$state', '$location', '$http', '$stateParams', '$timeout', 'Flash', 'CounselFactory'];
function CounselItemCtl($scope, $state, $location, $http, $stateParams, $timeout, Flash, CounselFactory) {
  //----- Set Init Params:
  let counsel_id = $stateParams.counsel_id;
  $scope.counsel = {};
  $scope.trainingPrograms = [];
  $scope.select = [];

  CounselFactory.getOne(counsel_id).then(data => {
    $scope.counsel = data;
    $scope.select.trainingProgram = {id: data.trainingProgramId};
    $scope.imgCount = (data.uploadedImg1 ? 1 : 0) +
        (data.uploadedImg2 ? 1 : 0) +
        (data.uploadedImg3 ? 1 : 0) +
        (data.uploadedImg4 ? 1 : 0);
  });
  CounselFactory.getTrainingProgramList().then(data => {
    $scope.trainingPrograms = data;
  });

  $scope.upload = function(counsel_id){
    let targetPictureIds = getTargetPictureIds();
    let myPictures = [...$scope.myPicture];
    let count = 0;
    const options = {
      maxSizeMB: 0.8,
      maxWidthOrHeight: 800,
    };
    myPictures.forEach((v, i) => {
      let file_data = new FormData();
      imageCompression(v, options).then(blob => {
        const file = new File([blob], blob.name, {type: blob.type});
        file_data.append('file', file);
        CounselFactory.uploadPicture(counsel_id, targetPictureIds[i], file_data).finally(function(fd_result) {
          count++;
          if(count === myPictures.length) {
            return location.reload();
          }
        });
      });
    })
  };
  $scope.validMyPicture = function(file_element) {
    let myPicture = [...file_element.files];
    if(myPicture.length > 0) {
      $scope.isIncorrectType = myPicture.some(v => !['image/jpg', 'image/jpeg', 'image/png'].includes(v.type));
      $scope.myPictureInfo = myPicture[0].name + (myPicture.length > 1 ? '、他' + (myPicture.length - 1) + 'ファイル' : '');
    } else {
      $scope.clearMyPicture();
    }
  };
  $scope.clearMyPicture = function() {
    $scope.myPicture = null;
    $scope.isOverSize = false;
    $scope.isIncorrectType = false;
    $scope.myPictureInfo = 4 - $scope.imgCount + 'ファイル登録可能';
  };
  function getTargetPictureIds() {
    let ids = [];
    if(!$scope.counsel.uploadedImg1) ids.push(1);
    if(!$scope.counsel.uploadedImg2) ids.push(2);
    if(!$scope.counsel.uploadedImg3) ids.push(3);
    if(!$scope.counsel.uploadedImg4) ids.push(4);

    return ids;
  }
}

function convertJSTString(value) {
  if(value.slice(-1) === 'Z') {
    let jst;
    jst = new Date(value);

    return jst.getFullYear() + '/' + ('0' + (jst.getMonth() + 1)).slice(-2) + '/' + ('0' + jst.getDate()).slice(-2);
  }

  return value.split('T')[0];
}

function getLineGraphOptions() {
  return [
    {id: 1, name: '体重の推移', label: '体重（kg）', column: 'weight', min: 20, max: 150},
    {id: 2, name: '体脂肪率の推移', label: '体脂肪率（％）', column: 'bodyFatPercentage', min: 3, max: 50},
    {id: 3, name: '筋肉量の推移', label: '筋肉量（kg）', column: 'muscleMass', min: 10, max: 90},
    {id: 4, name: '太もも周りの推移', label: '太もも周り（cm）', column: 'thighCircumference', min: 20, max: 100},
    {id: 5, name: 'お尻周りの推移', label: 'お尻周り（cm）', column: 'hipCircumference', min: 20, max: 150},
    {id: 6, name: 'お腹周りの推移', label: 'お腹周り（cm）', column: 'abdominalCircumference', min: 20, max: 150},
    {id: 7, name: '二の腕周りの推移', label: '二の腕周り（cm）', column: 'armCircumference', min: 10, max: 50},
  ];
}

},{}],3:[function(require,module,exports){
'use strict';

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
// InOut Management Controller
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++

angular
.module('sf_app')
.controller('InoutMgmtCtl', InoutMgmtCtl);

InoutMgmtCtl.$inject = ['$scope', '$state', '$location', '$http', '$stateParams', '$filter', 'InoutFactory'];
function InoutMgmtCtl($scope, $state, $location, $http, $stateParams, $filter, InoutFactory) {
  InoutFactory.get('self', 'all', 'all').then(function (data) {
    const events = data.map(v => {
      return {
        title: !v.isEntrance ? '入館' : '退館',
        start: v.accessedAt,
        color: !v.isEntrance ? 'green' : 'red',
        classNames: !v.isEntrance ? 'in' : 'out'
      };
    });
    const calendarEl = document.querySelector('#calendar');
    const calendar = new FullCalendar.Calendar(calendarEl, {
      themeSystem: 'bootstrap',
      locale: 'ja',
      firstDay: 0,
      navLinks: false,
      businessHours: false,
      editable: false,
      contentHeight: 'auto',
      defaultTimedEventDuration: '00:00',
      views: {
        dayGridMonth: {
          dayMaxEvents: 2,
        }
      },
      buttonText: {
        today: '今日',
        day: '日',
        week: '週',
        month: '月',
        list: 'リスト'
      },
      headerToolbar: {
        center: '',
        left: 'title today',
        right: 'dayGridMonth listMonth prev next',
      },
      dateClick: function(info) {
        calendar.changeView('dayGridDay', info.dateStr);
      },
      eventClick: function(info) {
        calendar.changeView('dayGridDay', info.event.startStr);
      },
      moreLinkContent: function(args){
        const number = args.num;
        if(number >= 10) {
          return `+α`;
        }
        return `+${number}件`;
      },
      dayCellContent: function (e) {
        e.dayNumberText = e.dayNumberText.replace('日', '');
      },
      validRange: function() {
        return {
          start: '2020-01-01',
          end: `${new Date().getFullYear() + 1}-01-01`,
        };
      },
      eventSources: [{
        googleCalendarApiKey: 'AIzaSyBAVtelIrW2nNaefyxUPtSGHSWKmhcomY0',
        googleCalendarId: 'japanese__ja@holiday.calendar.google.com',
        display: 'background',
        color: '#ffeaea'
      }],
      events: events || [],
    });

    calendar.render();
  });
}

},{}],4:[function(require,module,exports){
'use strict';

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Member Management Controller
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++

angular
.module('sf_app')
.controller('MemberMgmtCtl', MemberMgmtCtl);


MemberMgmtCtl.$inject = ['$scope', '$state', '$location', '$http', '$filter', 'Flash', 'MemberFactory', '$window', '$sessionStorage', 'shopId'];
function MemberMgmtCtl($scope, $state, $location, $http, $filter, Flash, MemberFactory, $window, $sessionStorage, shopId) {
  //----- Set Init Params:
  $scope.relationships = get_relationship_list();
  //----- Set Options:
  $scope.paymentMethodOption = [
    {"id": "0", "name": "口座振替"},
    {"id": "1", "name": "クレジットカード"}
  ];
  $scope.changeToCreditCard = 1;
  //----- Member Info:
  $scope.update_list = function(useSession = false){
    MemberFactory.getOne('self').then(function(member) {
      $scope.member = member;
      $scope.loginIdReg = new RegExp('^(' + $scope.member.loginId + '|[0-9a-zA-Z]+[0-9a-zA-Z\\.\\-_]{0,199})$');
      $scope.paymentMethod = $scope.paymentMethodOption[$scope.member.payment.paymentMethod];
      let relationships = ['妻', '夫', '父', '母', '子', '兄弟、姉妹', 'その他'];
      $scope.relationship = relationships[$scope.member.emRelationship];
    });
  };
  $scope.update_list();
  //----- Get Payment Detail:
  $scope.getPayment = function(member_id) {
    $scope.singlePayment = {};
    MemberFactory.getPayment(member_id).then(function(data) {
      //console.log(data);
      $scope.singlePayment = data.payment;
      $scope.singlePayment.memberCode = data.memberCode;
      $scope.singlePayment.paymentMethod = String($scope.changeToCreditCard);
      $scope.getGmo = {};
      if(data.gmo) {
        $scope.getGmo = data.gmo;
        $scope.getGmo.expireYearAt = String(data.gmo.Expire).substring(0,2);
        $scope.getGmo.expireMonthAt = String(data.gmo.Expire).substring(2);
        $scope.getGmo.security = '****';
      }
    });
  }
  //----- Update Payment:
  $scope.savePayment = function() {
    delete $scope.singlePayment.status;
    delete $scope.singlePayment.id;
    if($scope.singlePayment.btAccountName) { // Convert Kana:
      $scope.singlePayment.btAccountName = convertFullToHalf($scope.singlePayment.btAccountName);
    }
    $scope.singlePayment.paymentMethod = $scope.changeToCreditCard;
    var member_id = $scope.singlePayment.memberId;
    if(parseInt($scope.singlePayment.paymentMethod) == 0) { //if bank transfer
      MemberFactory.createPayment(member_id, $scope.singlePayment).then(function(data) {
        $('html,body').animate({ scrollTop: 0 }, 1);
        Flash.create('success', '支払方法を更新しました', 5000, true);
        $scope.update_list();
        return $scope.getPayment(member_id);
      });
    } else if(parseInt($scope.singlePayment.paymentMethod) == 1) { //if credit card
      $scope.singlePayment.gmo = {};
      $scope.singlePayment.gmo.username = $scope.getGmo.HolderName;
      //--- GMO
      var hodername = String($scope.getGmo.HolderName);
      var cardno = String($scope.getGmo.CardNo);
      var expire = String($scope.getGmo.expireYearAt) + ('0' + String($scope.getGmo.expireMonthAt)).slice(-2);
      var securitycode = String($scope.getGmo.security);
      Multipayment.init(shopId);
      Multipayment.getToken({
        holdername: hodername,
        cardno: cardno,
        expire: expire,
        securitycode: securitycode
      }, function(res) {
        //console.log(res);
        if(res.resultCode == '000' && res.tokenObject.token) {
          var gmo_token = res.tokenObject.token;
          //console.log(gmo_token);
          $scope.singlePayment.gmo.token = gmo_token;
          //console.log($scope.singlePayment);
          MemberFactory.createPayment(member_id, $scope.singlePayment).then(function(data) {
            //console.log(data);
            if(!data.message) {
              Flash.create('success', '支払方法を更新しました', 5000, true);
              $scope.update_list();
              return $scope.getPayment(member_id);
            } else {
              Flash.create('warning', data.message, 5000, true);
              return $scope.getPayment(member_id);
            }
          });
        } else {
          Flash.create('warning', 'クレジットカード情報を再度入力してください', 5000, true);
        }
      });
    }
  };
  $scope.saveNewEmail = function() {
    let json = {};
    json.name = 'email';
    json.value = $scope.newEmail;
    MemberFactory.exists(json).then(function(exists) {
      if (exists) {
        Flash.create('warning', 'すでに使用されているメールアドレスです', 5000, true);
      } else {
        json = {};
        json.id = $scope.member.id;
        json.email = $scope.newEmail;
        MemberFactory.upsert(json).then(function (data) {
          Flash.create('success', 'メールアドレスを更新しました', 5000, true);
          $scope.newEmail = null;
          $scope.update_list();
        });
      }
    });
  };
  $scope.saveNewLoginId = function() {
    let json = {};
    json.name = 'loginId';
    json.value = $scope.newLoginId;
    MemberFactory.exists(json).then(function(exists) {
      if (exists) {
        Flash.create('warning', 'すでに使用されているログインIDです', 5000, true);
      } else {
        json = {};
        json.id = $scope.member.id;
        json.loginId = $scope.newLoginId;
        MemberFactory.upsert(json).then(function (data) {
          Flash.create('success', 'ログインIDを更新しました', 5000, true);
          $scope.newLoginId = null;
          $scope.update_list();
        });
      }
    });
  };
  $scope.updatePassword = function() {
    if ($scope.newPassword1 !== $scope.newPassword2) {
      return Flash.create('warning', '新しいパスワードと確認は同じものを入力してください', 5000, true);
    }
    let json = {};
    json.password = $scope.nowPassword;
    json.loginId = $scope.member.loginId;
    MemberFactory.checkPassword(json).then(function(isTrue) {
      if (isTrue) {
        let json = {};
        json.id = $scope.member.id;
        json.password = $scope.newPassword1;
        json.changePassFromMyPage = true;
        MemberFactory.upsert(json).then(function (data) {
          Flash.create('success', 'パスワードを更新しました', 5000, true);
          $scope.nowPassword = null;
          $scope.newPassword1 = null;
          $scope.newPassword2 = null;
          $scope.update_list();
        });
      } else {
        Flash.create('warning', '現在のパスワードを再度入力してください', 5000, true);
      }
    });
  };
  $scope.updateEm = function() {
    let json = {};
    json.id = $scope.member.id;
    json.emLastName = $scope.newEmLastName;
    json.emFirstName = $scope.newEmFirstName;
    json.emLastNameKana = $scope.newEmLastNameKana;
    json.emFirstNameKana = $scope.newEmFirstNameKana;
    json.emRelationship = $scope.newEmRelationship.id;
    json.emPhone = $scope.newEmPhone1 + '-' + $scope.newEmPhone2 + '-' + $scope.newEmPhone3;
    MemberFactory.upsert(json).then(function (data) {
      Flash.create('success', '緊急連絡先を更新しました', 5000, true);
      $scope.update_list();
    });
  };
  $scope.clearModal = function() {
    $scope.newEmLastName = $scope.member.emLastName;
    $scope.newEmFirstName = $scope.member.emFirstName;
    $scope.newEmLastNameKana = $scope.member.emLastNameKana;
    $scope.newEmFirstNameKana = $scope.member.emFirstNameKana;
    $scope.newEmRelationship = {id: $scope.member.emRelationship};
    let member_phone_list = $scope.member.emPhone.split('-');
    $scope.newEmPhone1 = member_phone_list[0];
    $scope.newEmPhone2 = member_phone_list[1];
    $scope.newEmPhone3 = member_phone_list[2];
    $scope.newEmail = null;
    $scope.newLoginId = null;
    $scope.nowPassword = null;
    $scope.newPassword1 = null;
    $scope.newPassword2 = null;
  };
  function get_relationship_list() {
    var relationship_list = [
      {
        id: 0,
        name: "妻"
      }, {
        id: 1,
        name: "夫"
      }, {
        id: 2,
        name: "父"
      }, {
        id: 3,
        name: "母"
      }, {
        id: 4,
        name: "子"
      }, {
        id: 5,
        name: "兄弟、姉妹"
      }, {
        id: 6,
        name: "その他"
      }
    ];
    return relationship_list;
  }
}

function convertFullToHalf(before){
  let full = new Array(
      'ア','イ','ウ','エ','オ','カ','キ','ク','ケ','コ'
      ,'サ','シ','ス','セ','ソ','タ','チ','ツ','テ','ト'
      ,'ナ','ニ','ヌ','ネ','ノ','ハ','ヒ','フ','ヘ','ホ'
      ,'マ','ミ','ム','メ','モ','ヤ','ヰ','ユ','ヱ','ヨ'
      ,'ラ','リ','ル','レ','ロ','ワ','ヲ','ン'
      ,'ガ','ギ','グ','ゲ','ゴ','ザ','ジ','ズ','ゼ','ゾ'
      ,'ダ','ヂ','ヅ','デ','ド','バ','ビ','ブ','ベ','ボ'
      ,'パ','ピ','プ','ペ','ポ'
      ,'ァ','ィ','ゥ','ェ','ォ','ャ','ュ','ョ','ッ'
      ,'゛','°','、','。','「','」','ー','・'
  );
  let half = new Array(
      'ｱ','ｲ','ｳ','ｴ','ｵ','ｶ','ｷ','ｸ','ｹ','ｺ'
      ,'ｻ','ｼ','ｽ','ｾ','ｿ','ﾀ','ﾁ','ﾂ','ﾃ','ﾄ'
      ,'ﾅ','ﾆ','ﾇ','ﾈ','ﾉ','ﾊ','ﾋ','ﾌ','ﾍ','ﾎ'
      ,'ﾏ','ﾐ','ﾑ','ﾒ','ﾓ','ﾔ','ｲ','ﾕ','ｴ','ﾖ'
      ,'ﾗ','ﾘ','ﾙ','ﾚ','ﾛ','ﾜ','ｦ','ﾝ'
      ,'ｶﾞ','ｷﾞ','ｸﾞ','ｹﾞ','ｺﾞ','ｻﾞ','ｼﾞ','ｽﾞ','ｾﾞ','ｿﾞ'
      ,'ﾀﾞ','ﾁﾞ','ﾂﾞ','ﾃﾞ','ﾄﾞ','ﾊﾞ','ﾋﾞ','ﾌﾞ','ﾍﾞ','ﾎﾞ'
      ,'ﾊﾟ','ﾋﾟ','ﾌﾟ','ﾍﾟ','ﾎﾟ'
      ,'ｧ','ｨ','ｩ','ｪ','ｫ','ｬ','ｭ','ｮ','ｯ'
      ,'ﾞ','ﾟ','､','｡','｢','｣','ｰ','･'
  );
  let after = "";
  for (let i=0;i<before.length;i++){
    let before_char = before.charAt(i);
    let full_index = full.indexOf(before_char);
    if(full_index >= 0){
      before_char = half[full_index];
    }
    after += before_char;
  }
  after = after.replace(/　/g," ");
  return after;
}


},{}],5:[function(require,module,exports){
'use strict';

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Notification Management Controller
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++

angular
.module('sf_app')
.controller('NotificationMgmtCtl', NotificationMgmtCtl)
.controller('NotificationEditCtl', NotificationEditCtl);

NotificationMgmtCtl.$inject = ['$scope', '$state', '$location', '$http', '$stateParams', '$filter', 'NotificationFactory', 'MemberFactory'];
function NotificationMgmtCtl($scope, $state, $location, $http, $stateParams, $filter, NotificationFactory, MemberFactory) {
  //----- Set Init Params:
  $scope.notification = {};
  $scope.member = [];

  NotificationFactory.get().then(data => {
    if (Array.isArray(data) && data.length > 0) {
      let now = new Date();
      $scope.notification.plan = data.find(v => {
        return v.requestType === 3 && (
            v.status === 0 || v.status === 1 ||
            (v.status === 2 && new Date(v.startDate) > now)
        );
      });
      $scope.notification.payment = data.find(v => {
        return v.requestType === 6 && (
            v.status === 0 || v.status === 1 ||
            (v.status === 2 && new Date(v.startDate) > now)
        );
      });
      let lockerEnable = data.find(v => {
        return v.requestType === 0 && v.status !== 3;
      });
      let lockerDisable = data.find(v => {
        return v.requestType === 1 && v.status !== 3;
      });

      let isProcessed = false, newerData;
      if(lockerEnable && lockerDisable) {
        newerData = lockerEnable.createdAt > lockerDisable.createdAt ? lockerEnable : lockerDisable;
      } else if(lockerEnable || lockerDisable) {
        newerData = lockerEnable ? lockerEnable : lockerDisable;
      }
      if(newerData && newerData.status === 2) {
        isProcessed = (newerData.requestType === 0 && new Date(newerData.startDate) < now) || (newerData.requestType === 1 && new Date(newerData.endDate) < now);
      }
      $scope.notification.locker = isProcessed ? undefined : newerData;
    }
  });
  MemberFactory.getOne('self').then(function(member) {
    $scope.member = member;
  });
}

NotificationEditCtl.$inject = ['$scope', '$state', '$location', '$http', '$stateParams', '$filter', 'NotificationFactory', 'MemberFactory', 'StudioFactory', 'Flash', 'shopId'];
function NotificationEditCtl($scope, $state, $location, $http, $stateParams, $filter, NotificationFactory, MemberFactory, StudioFactory, Flash, shopId) {
  //----- Set Init Params:
  let now = new Date();
  $scope.select = {};
  $scope.member = [];
  NotificationFactory.getDeadLine().then(dead_line => {
    $scope.startDateOptions = getStartDateOptions(now, dead_line);
  });
  $scope.startDateOptionsForLocker = getStartDateOptionsForLocker(now);
  $scope.startDates = get_date_list();
  let modify = now.getDate() > 9 ? 2 : 1;
  $scope.nextPaymentDateCc = new Date(now.getFullYear(), now.getMonth() + modify, 1);
  $scope.nextPaymentDateBt = new Date(now.getFullYear(), now.getMonth() + modify - 1, 1);
  $scope.cc_expire_months = get_month_list();
  $scope.cc_expire_years = get_cc_year_list();

  MemberFactory.getOne('self').then(function(member) {
    $scope.member = member;
    StudioFactory.list(member.studio.id).then(function(data) {
      $scope.studioPlans = data.studioPlans;
      $scope.studioLockerPlans = data.studioLockerPlans;
      StudioFactory.getProduct(member.studio.id).then(function(data) {
        $scope.products = [];
        if (Array.isArray(data)) {
          $scope.products = data.filter((v) => {return v.status === 0});
        }
      });
    });
  });

  $scope.save = function() {
    let json = {},
        member = $scope.member,
        select = $scope.select;
    now = new Date();
    let message = '諸届を申請しました。';
    $scope.isButtonDisable = true;

    json.studioId = member.studio.id;
    json.memberId = member.id;
    json.requestMonth = now.getFullYear() + '-' + ('0' + (now.getMonth() + 1)).slice(-2);
    if(select.studioPlan) {
      json.requestType = 3;
      json.startDate = select.startDate.date;
      json.beforeStudioPlanId = member.studioPlan.id;
      json.afterStudioPlanId = select.studioPlan.id;
      notificationUpdate(json, message);
    } else if(select.singlePayment) {
      if(Number($scope.select.singlePayment.paymentMethod) === 1
        && !new RegExp('^[a-zA-Z0-9\\x20\,\.\\-\/]+$').test($scope.select.getGmo.HolderName)) {
        Flash.create('warning', '名義には半角英字および記号を入力してください', 5000, true);
        $('html,body').animate({scrollTop: 0}, '1');
        $scope.isButtonDisable = false;
        return false;
      }
      json.requestType = 6;
      json.beforePaymentMethod = member.payment.paymentMethod;
      json.afterPaymentMethod = Number(select.singlePayment.paymentMethod);

      $scope.select.singlePayment.memberId = member.id;
      $scope.select.singlePayment.memberCode = member.memberCode;

      if(select.getGmo) {
        json.status = 2;
        $scope.select.singlePayment.gmo = {};
        $scope.select.singlePayment.gmo.username = $scope.select.getGmo.HolderName;
        //--- GMO
        let hodername = String($scope.select.getGmo.HolderName);
        let cardno = String($scope.select.getGmo.CardNo);
        let expire = String($scope.select.getGmo.expireYearAt) + ('0' + String($scope.select.getGmo.expireMonthAt)).slice(-2);
        let securitycode = String($scope.select.getGmo.security);
        Multipayment.init(shopId);
        Multipayment.getToken({
          holdername: hodername,
          cardno: cardno,
          expire: expire,
          securitycode: securitycode
        }, function(res) {
          //console.log(res);
          if(res.resultCode == '000' && res.tokenObject.token) {
            let gmo_token = res.tokenObject.token;
            $scope.select.singlePayment.gmo.token = gmo_token;
            MemberFactory.createPayment(member.id, $scope.select.singlePayment).then(function(data) {
              if(!data.message) {
                let modify = now.getDate() > 9 ? 2 : 1;
                json.startDate = new Date(now.getFullYear(), now.getMonth() + modify, 1);
                message = '支払方法を更新しました';
                notificationUpdate(json, message);
              } else {
                Flash.create('warning', data.message, 5000, true);
                $scope.isButtonDisable = false;
              }
            });
          } else {
            Flash.create('warning', 'クレジットカード情報を再度入力してください', 5000, true);
            $scope.isButtonDisable = false;
          }
        });
      } else {
        let modify = now.getDate() > 9 ? 2 : 1;
        json.startDate = new Date(now.getFullYear(), now.getMonth() + modify, 0);
        message = 'お手続きについてご案内いたします。店舗へお越し下さい。';
        notificationUpdate(json, message);
      }
    }else {
      json.requestType = Number(select.isUseLocker);
      json.beforeLockerNumberId = member.lockerNumberId;
      if(json.requestType === 0) {
        let dateStr = select.startDate.id.split('-');
        json.startDate = new Date(Number(dateStr[0]), Number(dateStr[1]) - 1, Number(dateStr[2]));
        // json.afterLockerPlanProductId = select.lockerPlanProduct.id;
      } else if(json.requestType === 1) {
        json.endDate = new Date(select.endDate.date.setMonth(select.endDate.date.getMonth() + 1)).setDate(0);
      }
      message = '使用開始日に店舗へお越し下さい。ロッカーキーをお渡しします。';
      notificationUpdate(json, message);
    }
  };
  let notificationUpdate = function(json, message) {
    NotificationFactory.upsert(json).then(v => {
      if (!v) {
        Flash.create('danger', '諸届を申請できませんでした。', 5000, true);
      } else {
        Flash.create('success', message, 5000, true);
      }
      return $state.go('sf_app.notification.list');
    });
  };
  $scope.notIn = function(val) {
    return function (x) {
      return x.id !== val;
    };
  };
  //----- Functions:
  function get_date_list() {
    let start_date = 1;
    let end_date = 31;
    let date_list = [];
    for ( let i = start_date; i <= end_date; i++ ) {
      date_list.push({id: i, name: i + '日'});
    }
    return date_list;
  }
  $scope.getFutureDate = function (val_str) {
    return function (x) {
      if(!$scope.select.startDate1) {
        return x;
      }
      let now = new Date();
      let val = new Date($scope.select.startDate1.date);
      if(now.getMonth() !== val.getMonth()) {
        return x;
      }
      return Number(x['id']) > now.getDate() + 1;
    };
  };
  function get_cc_year_list() {
    const now = new Date();
    const start_year = now.getFullYear();
    const end_year = now.getFullYear() + 10;
    let year_list = [];
    for ( let i = start_year; i <= end_year; i++ ) {
      year_list.push(String(i).slice( -2 ));
    }
    return year_list;
  }

  function get_month_list() {
    const start_month = 1;
    const end_month = 12;
    let month_list = [];
    for ( let i = start_month; i <= end_month; i++ ) {
      month_list.push(i);
    }
    return month_list;
  }
}

function getStartDateOptions(now, dead_line) {
  let options = [];

  for(let i=0; i<4; i++) {
    let hidden = i === 0 || (now.getDate() > dead_line && i === 1) || (now.getDate() <= dead_line && i === 3),
        lastUseHidden = i === 3 ||  (now.getDate() > dead_line && i === 0) || (now.getDate() <= dead_line && i === 2),
        lockerUseHidden = i === 2 || i === 3,
        startDate;

    startDate = new Date(now.getFullYear(), now.getMonth() + i, 1);
    options.push({
      id: startDate.getFullYear() + '-' + (startDate.getMonth() + 1),
      name: String(startDate.getFullYear()) + '年' + ('0' + String(startDate.getMonth() + 1)).slice(-2) + '月',
      date: startDate,
      forNextMonth: new Date(startDate.toDateString()).setMonth(new Date(startDate.toDateString()).getMonth() + 1),
      forLastMonth: new Date(startDate.toDateString()).setMonth(new Date(startDate.toDateString()).getMonth() - 1),
      forTwoMonthAgo: new Date(startDate.toDateString()).setMonth(new Date(startDate.toDateString()).getMonth() - 2),
      hidden: hidden,
      lastUseHidden: lastUseHidden,
      lockerUseHidden: lockerUseHidden
    });
  }

  return options;
}

function getStartDateOptionsForLocker(now) {
  let options = [];

  for(let i = 2; i < 14; i++) {
    let startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + i);
    let strArr = [String(startDate.getFullYear()), ('0' + String(startDate.getMonth() + 1)).slice(-2), ('0' + String(startDate.getDate())).slice(-2)];
    options.push({
      id: strArr[0] + '-' + strArr[1] + '-' + strArr[2],
      name: strArr[0] + '年' + strArr[1] + '月' + strArr[2] + '日',
    });
  }

  return options;
}

},{}],6:[function(require,module,exports){
'use strict';

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Program Management Controller
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++

angular
.module('sf_app')
.controller('ProgramTopCtl', ProgramTopCtl)
.controller('ProgramMgmtCtl', ProgramMgmtCtl)
.controller('ProgramItemCtl', ProgramItemCtl);

ProgramTopCtl.$inject = ['$scope', '$state', '$location', '$http', '$filter', '$stateParams', 'Flash', 'CounselFactory'];
function ProgramTopCtl($scope, $state, $location, $http, $filter, $stateParams, Flash, CounselFactory) {
  //----- Set Init Params:
  $scope.items = [];
  CounselFactory.getTrainingProgramList().then(function(data) {
    if(data.length > 0) {
      $scope.items = data.reduce((p, x) => (p.findIndex(({level}) => level === x.level) !== -1) ? p : [...p, {level: x.level, icon: x.icon}], []);
    }
  });
}

ProgramMgmtCtl.$inject = ['$scope', '$state', '$location', '$http', '$stateParams', '$timeout', 'Flash', 'CounselFactory', 'MemberFactory'];
function ProgramMgmtCtl($scope, $state, $location, $http, $stateParams, $timeout, Flash, CounselFactory, MemberFactory) {
  //----- Set Init Params:
  let level = Number($stateParams.level);
  $scope.items = [];
  MemberFactory.getOne('self').then(function(member) {
    $scope.studio = member.studio;
  });
  CounselFactory.getTrainingProgramList().then(function(data) {
    if(data.length > 0) {
      $scope.items = data.filter(v => v.level === level);
    }
  });

  $scope.download = function(uploaded_pdf, level, training_name) {
    if($scope.isDisabledDownload) {
      return false;
    }
    $scope.isDisabledDownload = true;
    CounselFactory.getTrainingPdf(uploaded_pdf).then(result => {
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      let u8 = new Uint8Array(result.data),
          blob = new Blob([u8], {type: "application/pdf"}),
          url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = 'トレーニング_ステップ' + level + '_' + training_name + '.pdf';
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 200);
    }).finally(() => $scope.isDisabledDownload = false);
  }
}

ProgramItemCtl.$inject = ['$scope', '$state', '$location', '$http', '$stateParams', '$timeout', 'Flash', 'CounselFactory', '$sce'];
function ProgramItemCtl($scope, $state, $location, $http, $stateParams, $timeout, Flash, CounselFactory, $sce) {
  //----- Set Init Params:
  let uploadedPdf = $stateParams.uploaded_pdf;
  CounselFactory.getTrainingPdf(uploadedPdf).then(result => {
    let u8 = new Uint8Array(result.data);
    let blob = new Blob([u8], {type: 'application/pdf'});
    let fileURL = URL.createObjectURL(blob);
    $scope.content = $sce.trustAsResourceUrl(fileURL);
  });
}

},{}],7:[function(require,module,exports){
'use strict';

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Member Management Controller
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++

angular
.module('sf_app')
.controller('MemberTransactionMgmtCtl', MemberTransactionMgmtCtl);

const INVOICE_CREATE_DATE = 12;
const INVOICE_CREATE_HOUR = 2;

MemberTransactionMgmtCtl.$inject = ['$scope', '$state', '$location', '$http', '$stateParams', '$filter', 'MemberFactory', 'StudioFactory', 'TransactionFactory'];
function MemberTransactionMgmtCtl($scope, $state, $location, $http, $stateParams, $filter, MemberFactory, StudioFactory, TransactionFactory) {
  //----- Set Init Params:
  let now = new Date();
  let initial_year = 2015;
  $scope.showListTable = false;
  $scope.studio = {};
  $scope.items = [];
  $scope.select = {};
  $scope.select.year = null;
  $scope.select.month = null;
  $scope.pageLimit = 50;
  $scope.limitBegin = 0;
  $scope.yearPayOption = tran_get_year_list(initial_year, now.getFullYear() + 1);
  $scope.monthPayOption = tran_get_month_list();
  $scope.statusOption = tran_get_status_option_full();
  $scope.searching = true;
  $scope.singleTransaction = {};
  tran_set_settlement_list(now, $scope);
  $scope.isBeforeCreateInvoice = tran_get_is_before_create_invoice(now);
  $scope.thisIssueMonth = tran_get_this_Issue_Month(now);
  var member_id = $stateParams.member_id;
  //----- Click Pager:
  $scope.pagerClick = function (num) {
    $scope.limitBegin = num * $scope.pageLimit;
  };
  //----- Return Pager Array:
  $scope.pagerArr = function(num) {
    num = Math.ceil(num);
    var array = [];
    for (var i = 0; i < num; i++) {
      array[i] = i;
    }
    return array;
  };
  $scope.update_list = function(){
    MemberFactory.getOne('self').then(function(data) {
      $scope.member = data;
      StudioFactory.list(data.studio.id).then(function(data) {
        $scope.studio = data;
        //console.log(data);
      });
    });
    MemberFactory.getTransaction('self').then(function(data) {
      $scope.searching = false;
      $scope.items = data;
      if (Array.isArray($scope.items)) {
        angular.forEach($scope.items, (v) => getSettlementDate(v, $scope.isBeforeCreateInvoice, $scope.settlementList));
      }
      $scope.showListTable = $scope.items.length !== 0;
    });
  };
  $scope.update_list();
  //----- Clear Filter Condition:
  $scope.clearFilter = function() {
    $scope.select.year = null;
    $scope.select.month = null;
    $scope.select.settleMonth = "";
    $scope.keyword = "";
    $scope.statusFilter = null;
  };
  $scope.updateSelectSettleMonth = function() {
    $scope.select.settleMonth = "";
    if ($scope.select.year !== null) {
      $scope.select.settleMonth += $scope.select.year.id + '年';
    }
    if ($scope.select.month !== null) {
      $scope.select.settleMonth += $scope.select.month.id + '月';
    }
  };
  //----- Download Receipt;
  $scope.getReceipt = function(transaction){
    $scope.receiptInput = {};
    $scope.singleTransaction = transaction;
  }
  $scope.downloadReceipt = function(transaction_id, member_id){
    var file_name = String(member_id).replace("-","") + String(transaction_id) + '.pdf';
    html2canvas(document.getElementById('transactionReceipt'), {
      backgroundColor: '#FFFFFF',
      width: 500,
      height: 800,
      scrollX: 0,
      scrollY: 0
    }).then(function(canvas) {
      TransactionFactory.downloadedReceipt({id: transaction_id, isDownloadedReceipt: 1}).then(v => {
        const targetTransaction = $scope.items.find(item => item.id === transaction_id);
        targetTransaction.isDownloadedReceipt = '1';
        var pdf = new jsPDF('p', 'pt', 'a4', false);
        var width = pdf.internal.pageSize.width;
        pdf.addImage(canvas, 'PNG', 0, 0, width, 0);
        pdf.save(file_name);
      });
    });
  }
}

function tran_get_status_option_full() {
  var transaction_status_option = [
    {"id": "1", "name": "削除済"},
    {"id": "2", "name": "請求確定"},
    {"id": "5", "name": "入金済"},
    {"id": "6", "name": "未納"},
    {"id": "7", "name": "払い戻し済"},
    {"id": "8", "name": "請求停止"},
    {"id": "9", "name": "受け取り"},
  ];
  return transaction_status_option;
}

function tran_get_year_list(start_year, end_year) {
  var now = new Date();
  var start_year = start_year || 2015;
  var end_year = end_year || now.getFullYear() + 1;
  var year_list = [];
  for ( var i = start_year; i <= end_year; i++ ) {
    var year_object = {
      "id": i,
      "name": String(i) + "年"
    }
    year_list.push(year_object);
  }
  return year_list;
}

function tran_get_month_list() {
  var month_list = [];
  for ( var i = 1; i <= 12; i++ ) {
    var month_object = {
      "id": i,
      "name": String(i) + "月"
    }
    month_list.push(month_object);
  }
  return month_list;
}

/**
 * 現在日時が当月のinvoiceデータ作成前かを判定する
 *
 * @param {Date} now
 * @returns {boolean}
 */
function tran_get_is_before_create_invoice (now)
{
  let year, month, threshold;
  year = now.getFullYear();
  month = now.getMonth() + 1;
  threshold = new Date(year, (month - 1), INVOICE_CREATE_DATE, INVOICE_CREATE_HOUR);

  return (now < threshold) ? true : false;
}

/**
 * 現在の年月を'ｙｙｙｙ-MM'の文字列で取得する
 *
 * @param {Date} now
 * @returns {string}
 */
function tran_get_this_Issue_Month (now)
{
  var year, month;
  year = now.getFullYear();
  month = now.getMonth() + 1;

  return year + '-' + ('0' + month).slice(-2);
}

/**
 * 支払年月を設定する
 *
 * @param now
 * @param scope
 */
function tran_set_settlement_list (now, scope)
{
  let thisYear, thisMonth, dateForCreditCard, dateForAccountTransfer;
  scope.settlementList = {};
  thisYear = now.getFullYear();
  thisMonth = now.getMonth();
  dateForCreditCard = 1;
  dateForAccountTransfer = 28;

  scope.settlementList.thisMonthAccountTransfer = new Date(thisYear, thisMonth, dateForAccountTransfer);
  scope.settlementList.nextMonthAccountTransfer = new Date(thisYear, (thisMonth + 1), dateForAccountTransfer);
  scope.settlementList.nextMonthCreditCard = new Date(thisYear, (thisMonth + 1), dateForCreditCard);
  scope.settlementList.theMonthAfterNextCreditCard = new Date(thisYear, (thisMonth + 2), dateForCreditCard);

  // invoice == null用に保持
  scope.settlementList.thisYear = thisYear;
  scope.settlementList.thisMonth = thisMonth + 1;
}

/**
 * 支払年月を取得する
 *
 * @param item
 * @param is_before_create_invoice
 * @param settlement_list
 * @returns {string}
 */
function getSettlementDate(item, is_before_create_invoice, settlement_list)
{
  const paymentMethod = item.paymentMethod;
  const yearPay = item.yearPay;
  const monthPay = item.monthPay;
  const createdAt = new Date(item.createdAt);
  let thisMonth = [], nextMonth = [], lastMonth = [];
  let settlementDate = '-';

  // [0] => year, [1] => month
  thisMonth.push(settlement_list.thisYear);
  thisMonth.push(settlement_list.thisMonth);
  nextMonth.push(thisMonth[1] === 12 ? thisMonth[0] + 1 : thisMonth[0]);
  nextMonth.push(thisMonth[1] === 12 ? 1 : thisMonth[1] + 1);
  lastMonth.push(thisMonth[1] === 1 ? thisMonth[0] - 1 : thisMonth[0]);
  lastMonth.push(thisMonth[1] === 1 ? 12 : thisMonth[1] - 1);

  // 当月マンスリーバッチ後、かつ、請求確定
  if (
      item.status === 2
      && !is_before_create_invoice
  ) {
    const issueMonth = item.invoice.issueMonth.split('-');
    settlementDate = paymentMethod === 0
        ? new Date(parseInt(issueMonth[0]), parseInt(issueMonth[1]) - 1, 28)
        : new Date(parseInt(issueMonth[0]), parseInt(issueMonth[1]), 1);
  }
  // 当月マンスリーバッチ前、かつ、請求予定もしくは承認済み、かつ、yearPay, monthPayが当月
  else if (
      is_before_create_invoice
      && (item.status === 0 || item.status === 3)
      && (yearPay === thisMonth[0] && monthPay === thisMonth[1])
  ) {
    settlementDate = paymentMethod === 0
        ? new Date(thisMonth[0], thisMonth[1] - 1, 28)
        : new Date(nextMonth[0], nextMonth[1] - 1, 1);
  }
  // 当月マンスリーバッチ前、かつ、請求予定もしくは承認済み、かつ、自動作成データ
  else if (
      is_before_create_invoice
      && (item.status === 0 || item.status === 3)
      && item.isAutoGenerate === 0
  ) {
    // yearPay, monthPayが翌月
    if (yearPay === nextMonth[0] && monthPay === nextMonth[1]) {
      settlementDate = paymentMethod === 0
          ? new Date(thisMonth[0], thisMonth[1] - 1, 28)
          : new Date(nextMonth[0], nextMonth[1] - 1, 1);
    }
  }
  // 当月マンスリーバッチ前、かつ、請求予定もしくは承認済み、かつ、手動作成データ
  else if (
      is_before_create_invoice
      && (item.status === 0 || item.status === 3)
      && item.isAutoGenerate === 1
  ) {
    let startDate = new Date(lastMonth[0], lastMonth[1] - 1, 1);
    let endDate = new Date(thisMonth[0], thisMonth[1] - 1, 1);

    // 前月作成データ
    if (createdAt >= startDate && createdAt < endDate) {
      settlementDate = paymentMethod === 0
          ? new Date(thisMonth[0], thisMonth[1] - 1, 28)
          : new Date(nextMonth[0], nextMonth[1] - 1, 1);
    }
  }
  // 当月マンスリーバッチ後、かつ、請求予定もしくは承認済み
  else if (
      !is_before_create_invoice
      && (item.status === 0 || item.status === 3)
  ) {
    let startDate = new Date(thisMonth[0], thisMonth[1] - 1, 1);
    let endDate = new Date(nextMonth[0], nextMonth[1] - 1, 1);

    // yearPay, monthPayが翌月、もしくは、手動作成データかつ当月作成データ
    if (
        (yearPay === nextMonth[0] && monthPay === nextMonth[1])
        || (createdAt >= startDate && createdAt < endDate && item.isAutoGenerate === 1)
    ) {
      settlementDate = paymentMethod === 0
          ? new Date(nextMonth[0], nextMonth[1] - 1, 28)
          : new Date(nextMonth[0], nextMonth[1], 1);
    }
  }

  if (settlementDate === '-') {
    item.settlementMonth = settlementDate;
  } else {
    let settleMonth = settlementDate.getMonth() + 1;
    let settleYear = settlementDate.getFullYear();
    item.settlementMonth = settleYear + '年' + settleMonth + '月';
  }
}

},{}],8:[function(require,module,exports){
angular
.module('sf_app')
.directive('includeReplace', includeReplace)
.directive('a', preventClickDirective)
.directive('a', bootstrapCollapseDirective)
.directive('a', navigationDirective)
.directive('button', layoutToggleDirective)
.directive('a', layoutToggleDirective)
.directive('button', collapseMenuTogglerDirective)
.directive('div', bootstrapCarouselDirective)
.directive('toggle', bootstrapTooltipsPopoversDirective)
.directive('tab', bootstrapTabsDirective)
.directive('button', cardCollapseDirective)
.directive('saveToSession', saveToSession)
.directive('clearSession', clearSession)
.directive('showHelp', showHelp)
.directive('helpModal', helpModal);

function includeReplace() {
  var directive = {
    require: 'ngInclude',
    restrict: 'A',
    link: link
  }
  return directive;

  function link(scope, element, attrs) {
    element.replaceWith(element.children());
  }
}

//Prevent click if href="#"
function preventClickDirective() {
  var directive = {
    restrict: 'E',
    link: link
  }
  return directive;

  function link(scope, element, attrs) {
    if (attrs.href === '#'){
      element.on('click', function(event){
        event.preventDefault();
      });
    }
  }
}

//Bootstrap Collapse
function bootstrapCollapseDirective() {
  var directive = {
    restrict: 'E',
    link: link
  }
  return directive;

  function link(scope, element, attrs) {
    if (attrs.toggle=='collapse'){
      element.attr('href','javascript;;').attr('data-target',attrs.href.replace('index.html',''));
    }
  }
}

/**
* @desc Genesis main navigation - Siedebar menu
* @example <li class="nav-item nav-dropdown"></li>
*/
function navigationDirective() {
  var directive = {
    restrict: 'E',
    link: link
  }
  return directive;

  function link(scope, element, attrs) {
    if(element.hasClass('nav-dropdown-toggle') && angular.element('body').width() > 782) {
      element.on('click', function(){
        if(!angular.element('body').hasClass('compact-nav')) {
          element.parent().toggleClass('open').find('.open').removeClass('open');
        }
      });
    } else if (element.hasClass('nav-dropdown-toggle') && angular.element('body').width() < 783) {
      element.on('click', function(){
        element.parent().toggleClass('open').find('.open').removeClass('open');
      });
    }
  }
}

//Dynamic resize .sidebar-nav
sidebarNavDynamicResizeDirective.$inject = ['$window', '$timeout'];
function sidebarNavDynamicResizeDirective($window, $timeout) {
  var directive = {
    restrict: 'E',
    link: link
  }
  return directive;

  function link(scope, element, attrs) {

    if (element.hasClass('sidebar-nav') && angular.element('body').hasClass('fixed-nav')) {
      var bodyHeight = angular.element(window).height();
      scope.$watch(function(){
        var headerHeight = angular.element('header').outerHeight();

        if (angular.element('body').hasClass('sidebar-off-canvas')) {
          element.css('height', bodyHeight);
        } else {
          element.css('height', bodyHeight - headerHeight);
        }
      })

      angular.element($window).bind('resize', function(){
        var bodyHeight = angular.element(window).height();
        var headerHeight = angular.element('header').outerHeight();
        var sidebarHeaderHeight = angular.element('.sidebar-header').outerHeight();
        var sidebarFooterHeight = angular.element('.sidebar-footer').outerHeight();

        if (angular.element('body').hasClass('sidebar-off-canvas')) {
          element.css('height', bodyHeight - sidebarHeaderHeight - sidebarFooterHeight);
        } else {
          element.css('height', bodyHeight - headerHeight - sidebarHeaderHeight - sidebarFooterHeight);
        }
      });
    }
  }
}

//LayoutToggle
layoutToggleDirective.$inject = ['$interval'];
function layoutToggleDirective($interval) {
  var directive = {
    restrict: 'E',
    link: link
  }
  return directive;

  function link(scope, element, attrs) {
    element.on('click', function(){

      if (element.hasClass('sidebar-toggler')) {
        angular.element('body').toggleClass('sidebar-hidden');
        if (angular.element('body').hasClass('sidebar-hidden')) {
          angular.element('body').children('#side-nav-backdrop').remove();
        } else {
          angular.element('body').append('<div id="side-nav-backdrop" class="modal-backdrop fade"></div>');
        }
      }

      if (element.hasClass('aside-menu-toggler')) {
        angular.element('body').toggleClass('aside-menu-hidden');
      }
    });

    $(document).on('click', '#side-nav-backdrop', function() {
      angular.element('body').addClass('sidebar-hidden');
      angular.element('body').children('#side-nav-backdrop').remove();
    });
  }
}

//Collapse menu toggler
function collapseMenuTogglerDirective() {
  var directive = {
    restrict: 'E',
    link: link
  }
  return directive;

  function link(scope, element, attrs) {
    element.on('click', function(){
      if (element.hasClass('navbar-toggler') && !element.hasClass('layout-toggler')) {
        angular.element('body').toggleClass('sidebar-mobile-show')
      }
    })
  }
}

//Bootstrap Carousel
function bootstrapCarouselDirective() {
  var directive = {
    restrict: 'E',
    link: link
  }
  return directive;

  function link(scope, element, attrs) {
    if (attrs.ride=='carousel'){
      element.find('a').each(function(){
        $(this).attr('data-target',$(this).attr('href').replace('index.html','')).attr('href','javascript;;')
      });
    }
  }
}

//Bootstrap Tooltips & Popovers
function bootstrapTooltipsPopoversDirective() {
  var directive = {
    restrict: 'A',
    link: link
  }
  return directive;

  function link(scope, element, attrs) {
    if (attrs.toggle=='tooltip'){
      angular.element(element).tooltip();
    }
    if (attrs.toggle=='popover'){
      angular.element(element).popover();
    }
  }
}

//Bootstrap Tabs
function bootstrapTabsDirective() {
  var directive = {
    restrict: 'A',
    link: link
  }
  return directive;

  function link(scope, element, attrs) {
    element.click(function(e) {
      e.preventDefault();
      angular.element(element).tab('show');
    });
  }
}

//Card Collapse
function cardCollapseDirective() {
  var directive = {
    restrict: 'E',
    link: link
  }
  return directive;

  function link(scope, element, attrs) {
    if (attrs.toggle=='collapse' && element.parent().hasClass('card-actions')){

      if (element.parent().parent().parent().find('.card-body').hasClass('in')) {
        element.find('i').addClass('r180');
      }

      var id = 'collapse-' + Math.floor((Math.random() * 1000000000) + 1);
      element.attr('data-target','#'+id)
      element.parent().parent().parent().find('.card-body').attr('id',id);

      element.on('click', function(){
        element.find('i').toggleClass('r180');
      })
    }
  }
}

// セッション管理する値
function saveToSession() {
  var directive = {
    restrict: 'A',
    link: link
  }
  return directive;

  function link(scope, element, attrs) {

    var ngModelName = attrs.ngModel;

    // セッションから値を取得する
    var sessionValue = sessionStorage.getItem(ngModelName);

    if (isJson(sessionValue)) {
      sessionValue = JSON.parse(sessionValue);
    } else if (isDateStr(sessionValue)) {
      sessionValue = new Date(sessionValue);
    }

    // セッションに値が存在する場合はフォームにセットする
    if (isExist(sessionValue)) {
      scope[ngModelName] = sessionValue;
    }

    // 値が変更されたらセッションに値を保存する
    scope.$watch(ngModelName, function(newValue, oldValue, scope) {

      // データ型に合わせて値を変換する
      if (is("Object", newValue)) {
        var value = JSON.stringify(newValue);
      } else if (is("Date", newValue)) {
        value = newValue.toString();
      } else {
        value = newValue;
      }

      // 値をセッションに保存する
      sessionStorage.setItem(ngModelName, value);

    });

    // 指定の型か判定する
    function is(type, value) {

      var clas = Object.prototype.toString.call(value).slice(8, -1);
      return value !== undefined && value !== null && clas === type;
    }

    // JSONを判定する
    function isJson(str) {

      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    }

    // Date型に変換可能か判定する
    function isDateStr(str) {

      var date = new Date(str);
      if (date == 'Invalid Date') {
        return false;
      }
      return true;
    }
    
    // 値の有無を確認する
    function isExist(value) {

      if (value == null || value == "undefined" || value == '') {
        return false;
      }
      return true;
    }

    if (ngModelName == "select.studio") {
      setLimitBegin(scope);
      scope.update_list(true);
    }

  }

  // ページャーの位置を復元する
  function setLimitBegin (scope) {
    var limitBegin = sessionStorage.getItem('limitBegin');
    if (limitBegin != undefined && limitBegin != '' && limitBegin != null) {
      scope['limitBegin'] = limitBegin;
    }
  }
}

function clearSession () {
  let directive = {
    restrict: 'EA',
    link: link
  }
  return directive;

  function link() {
    // セッション情報をクリアする
    $('clear-session').on('click', function() {
      $.each(sessionStorage, function(index, value){
        if (index !== 'ngStorage-currentUser') {
          sessionStorage.removeItem(index);
        }
      })
    });
  }
}

function showHelp ($compile) {
  let directive = {
    restrict: 'E',
    link: link,
    template: '<span>ヘルプ <i class="fa fa-question-circle"></i></span>'
  };
  return directive;

  function link (scope, element) {
    $('show-help').on('click', function () {
      let hash = location.hash.replace('#!/', '');
      let helpImageName = hash.replace(/\//g, '_');
      $('#helpImageSource').attr('src', '/sf3_admin/img/help/' + helpImageName +'.png');
      $('#helpModalLabel').text(getTitle(helpImageName) + ' / 画面説明');
    });
  }

  function getTitle (currentPage) {
    switch (currentPage) {
      case 'member_':
        return '会員管理';
      case 'transaction_':
        return '売上管理';
      case 'inout_':
        return '入退館履歴管理';
      default:
        return '';
    }
  }
}

function helpModal ($compile) {
  let directive = {
    restrict: 'E',
    link: link,
  };
  return directive;

  function link (scope, element) {
    element.append($compile('<div class="modal fade" id="helpModal" tabindex="-1" role="dialog" aria-labelledby="helpModalLabel" aria-hidden="true">\n' +
        '    <div class="modal-dialog" role="document" style="padding: 0 30px; max-width: 100%;">\n' +
        '      <div class="modal-content">\n' +
        '        <div class="modal-header">\n' +
        '          <h5 class="modal-title" id="helpModalLabel"></h5>\n' +
        '          <button type="button" class="close" data-dismiss="modal" aria-label="Close">\n' +
        '            <span aria-hidden="true">&times;</span>\n' +
        '          </button>\n' +
        '        </div>\n' +
        '        <div class="modal-body" style="text-align: center;">\n' +
        '          <img id="helpImageSource" src="" style="width: 100%; max-width: 1200px;">\n' +
        '        </div>\n' +
        '        <div class="modal-footer">\n' +
        '          <button class="btn btn-secondary btn-sm" type="button" data-dismiss="modal"><i class="fa icon-close"></i>&nbsp;閉じる</button>\n' +
        '        </div>\n' +
        '      </div>\n' +
        '    </div>\n' +
        '  </div>')(scope));
  }

}

},{}],9:[function(require,module,exports){
'use strict';

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Counsel Factory
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++

angular
.module('sf_app')
.factory('CounselFactory', CounselFactory);

CounselFactory.$inject = ['$state', '$location', '$http', 'apiBaseUrl', 'http_config'];
function CounselFactory($state, $location, $http, apiBaseUrl, http_config) {
  let getOne = function(id) {
    let counselUrl = apiBaseUrl + '/counsel/' + id;
    return $http.get(counselUrl, http_config).then(
      function(response) { // If Success:
        if(response.status !== 200 || response.data.success !== true) {
          return $location.path('/error');
        }
        return response.data.result;
      },
      function(data) { //If Error:
        if(data.status === 401) {
          return $location.path('/login');
        } else {
          return $location.path('/error');
        }
      }
    );
  };
  let getTrainingProgramList = function() {
    let counselUrl = apiBaseUrl + '/training';
    return $http.get(counselUrl, http_config).then(
      function(response) { // If Success:
        if(response.status !== 200 || response.data.success !== true) {
          return $location.path('/error');
        }
        return response.data.result;
      },
      function(data) { //If Error:
        if(data.status === 401) {
          return $location.path('/login');
        } else {
          return $location.path('/error');
        }
      }
    );
  };
    let getTrainingPdf = function(id) {
      let counselUrl = apiBaseUrl + '/training/pdf/' + id;
      return $http.get(counselUrl, http_config).then(
        function(response) { // If Success:
          if(response.status !== 200) {
            return $location.path('/error');
          }
          return response.data.result;
        },
        function(data) { //If Error:
          if(data.status === 401) {
            return $location.path('/login');
          } else {
            return $location.path('/error');
          }
        }
      );
    };
  let uploadPicture = function(id, picture_id, file_data) {
    let counselUrl = apiBaseUrl + '/counsel/' + id + '/picture/' + picture_id;
    http_config.transformRequest = null;
    http_config.headers = {};
    http_config.headers['Content-type'] = undefined;
    return $http.post(counselUrl, file_data, http_config).then(
      function(response) { // If Success:
        if(response.status !== 200 || response.data.success !== true) {
          return $location.path('/error');
        }
        return response.data.result;
      }
    );
  };
  return {
    getOne: getOne,
    getTrainingProgramList: getTrainingProgramList,
    getTrainingPdf: getTrainingPdf,
    uploadPicture: uploadPicture,
  };
}

},{}],10:[function(require,module,exports){
'use strict';

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Inout Factory
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++

angular
.module('sf_app')
.factory('InoutFactory', InoutFactory);

InoutFactory.$inject = ['$state', '$location', '$http', 'apiBaseUrl', 'http_config'];
function InoutFactory($state, $location, $http, apiBaseUrl, http_config) {
  const get = function(id = 'self', year = 'all', month = 'all') {
    const inoutUrl = apiBaseUrl + '/member/' + id + '/inout/' + year + '/' + month;
    return $http.get(inoutUrl, http_config).then(
      function(response) { // If Success:
        if(response.status != 200 || response.data.success != true) {
          return $location.path('/error');
        }
        return response.data.result;
      },
      function(data) { //If Error:
        if(data.status == 401) {
          return $location.path('/login');
        } else {
          return $location.path('/error');
        }
      }
    );
  };
  return {
    get: get
  };
}

},{}],11:[function(require,module,exports){
'use strict';

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Member Factory
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++

angular
.module('sf_app')
.factory('MemberFactory', MemberFactory);

MemberFactory.$inject = ['$state', '$location', '$http', 'apiBaseUrl', 'http_config'];
function MemberFactory($state, $location, $http, apiBaseUrl, http_config) {
  const  getOne = function(id) {
    const  memberUrl = apiBaseUrl + '/member/' + id;
    return $http.get(memberUrl, http_config).then(
      function(response) { // If Success:
        if(response.status != 200 || response.data.success != true) {
          return $location.path('/error');
        }
        return response.data.result;
      },
      function(data) { //If Error:
        if(data.status == 401) {
          return $location.path('/login');
        } else {
          return $location.path('/error');
        }
      }
    );
  };
  const  upsert = function(json) {
    const  memberUrl = apiBaseUrl + '/member';
    return $http.post(memberUrl, json, http_config).then(
      function(response) { // If Success:
        if(response.status != 200 || response.data.success != true) {
          return $location.path('/error');
        }
        return response.data.result;
      },
      function(data) { //If Error:
        if(data.status == 401) {
          return $location.path('/login');
        } else {
          return $location.path('/error');
        }
      }
    );
  };
  const  getPayment = function(id) {
    const  memberUrl = apiBaseUrl + '/member/' + id + '/payment';
    return $http.get(memberUrl, http_config).then(
      function(response) { // If Success:
        if(response.status != 200 || response.data.success != true) {
          return $location.path('/error');
        }
        return response.data.result;
      },
      function(data) { //If Error:
        if(data.status == 401) {
          return $location.path('/login');
        } else {
          return $location.path('/error');
        }
      }
    );
  };
  const  createPayment = function(id, json) {
    const  memberUrl = apiBaseUrl + '/member/' + id + '/payment';
    return $http.post(memberUrl, json, http_config).then(
      function(response) { // If Success:
        if(response.status != 200 || response.data.success != true) {
          return $location.path('/error');
        }
        return response.data.result;
      },
      function(data) { //If Error:
        if(data.status == 401) {
          return $location.path('/login');
        } else {
          return $location.path('/error');
        }
      }
    );
  };
  const  getTransaction = function(id) {
    const  memberUrl = apiBaseUrl + '/member/' + id + '/transaction';
    return $http.get(memberUrl, http_config).then(
      function(response) { // If Success:
        if(response.status != 200 || response.data.success != true) {
          return $location.path('/error');
        }
        return response.data.result;
      },
      function(data) { //If Error:
        if(data.status == 401) {
          return $location.path('/login');
        } else {
          return $location.path('/error');
        }
      }
    );
  };
  const  exists = function(json) {
    const  memberUrl = apiBaseUrl + '/member/exists';
    return $http.post(memberUrl, json, http_config).then(
        function(response) { // If Success:
          if(response.status != 200 || response.data.success != true) {
            return $location.path('/error');
          }
          return response.data.result;
        },
        function(data) { //If Error:
          if(data.status == 401) {
            return $location.path('/login');
          } else {
            return $location.path('/error');
          }
        }
    );
  };
  const  checkPassword = function(json) {
    const  memberUrl = apiBaseUrl + '/member/auth';
    return $http.post(memberUrl, json, http_config).then(
        function(response) { // If Success:
          if(response.status != 200 || response.data.success != true) {
            return false;
          }
          return true;
        },
        function(data) { //If Error:
          if(data.status == 401) {
            return false;
          } else {
            return false;
          }
        }
    );
  };
  const  getCounsel = function(member_id) {
    const  memberUrl = apiBaseUrl + '/member/' + member_id + '/counsel';
    return $http.get(memberUrl, http_config).then(
      function(response) { // If Success:
        if(response.status != 200 || response.data.success != true) {
          return $location.path('/error');
        }
          return response.data.result;
      },
      function(data) { //If Error:
        if(data.status == 401) {
          return $location.path('/login');
        } else {
          return $location.path('/error');
        }
      }
    );
  };
  return {
    getOne: getOne,
    upsert: upsert,
    getPayment: getPayment,
    createPayment: createPayment,
    getTransaction: getTransaction,
    exists: exists,
    checkPassword: checkPassword,
    getCounsel: getCounsel,
  };
}

},{}],12:[function(require,module,exports){
'use strict';

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Notification Factory
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++

angular
.module('sf_app')
.factory('NotificationFactory', NotificationFactory);

NotificationFactory.$inject = ['$state', '$location', '$http', 'apiBaseUrl', 'http_config'];
function NotificationFactory($state, $location, $http, apiBaseUrl, http_config) {
  const get = function(id = 'self') {
    const NotificationUrl = apiBaseUrl + '/member/' + id + '/notification';
    return $http.get(NotificationUrl, http_config).then(
      function(response) { // If Success:
        if(response.status != 200 || response.data.success != true) {
          return $location.path('/error');
        }
        return response.data.result;
      },
      function(data) { //If Error:
        if(data.status == 401) {
          return $location.path('/login');
        } else {
          return $location.path('/error');
        }
      }
    );
  };
  const upsert = function(json) {
    const NotificationUrl = apiBaseUrl + '/notification';
    return $http.post(NotificationUrl, json, http_config).then(
        function(response) { // If Success:
          if(response.status != 200 || response.data.success != true) {
            return $location.path('/error');
          }
          return response.data.result;
        },
        function(data) { //If Error:
          if(data.status == 401) {
            return $location.path('/login');
          } else {
            return $location.path('/error');
          }
        }
    );
  };
  const getDeadLine = function() {
    const NotificationUrl = apiBaseUrl + '/batchSchedule';
    return $http.get(NotificationUrl, http_config).then(
      function(response) { // If Success:
        if(response.status != 200 || response.data.success != true) {
          return $location.path('/error');
        }
        return response.data.result.createTransaction - 2;
      },
      function(data) { //If Error:
        if(data.status == 401) {
          return $location.path('/login');
        } else {
          return $location.path('/error');
        }
      }
    );
  };
  return {
    get: get,
    upsert: upsert,
    getDeadLine: getDeadLine,
  };
}

},{}],13:[function(require,module,exports){
'use strict';

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Studio Factory
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++

angular
.module('sf_app')
.factory('StudioFactory', StudioFactory);

StudioFactory.$inject = ['$state', '$location', '$http', '$sessionStorage', 'apiBaseUrl', 'http_config'];
function StudioFactory($state, $location, $http, $sessionStorage, apiBaseUrl, http_config) {
  let list = function(scope) {
    let studioUrl = apiBaseUrl + '/studio';
    if(scope !== 'all') {
      studioUrl = apiBaseUrl + '/studio/' + scope;
    }
    return $http.get(studioUrl, http_config).then(
      function(response) { // If Success:
        if(response.status != 200 || response.data.success != true) {
          return $location.path('/error');
        }
        return response.data.result;
      },
      function(data) { //If Error:
        if(data.status == 401) {
          return $location.path('/login');
        } else {
          return $location.path('/error');
        }
      }
    );
  };
  let getTransaction = function(id, year, month) {
    let studioUrl = apiBaseUrl + '/studio/' + id + '/transaction/' + year + '/' + month;
    return $http.get(studioUrl, http_config).then(
      function(response) { // If Success:
        if(response.status != 200 || response.data.success != true) {
          return $location.path('/error');
        }
        return response.data.result;
      },
      function(data) { //If Error:
        if(data.status == 401) {
          return $location.path('/login');
        } else {
          return $location.path('/error');
        }
      }
    );
  };
  let getProduct = function(id) {
    let studioUrl = apiBaseUrl + '/studio/' + id + '/product';
    return $http.get(studioUrl, http_config).then(
      function(response) { // If Success:
        if(response.status != 200 || response.data.success != true) {
          return $location.path('/error');
        }
        return response.data.result;
      },
      function(data) { //If Error:
        if(data.status == 401) {
          return $location.path('/login');
        } else {
          return $location.path('/error');
        }
      }
    );
  };
  return {
    list: list,
    getTransaction: getTransaction,
    getProduct: getProduct,
  };
}

},{}],14:[function(require,module,exports){
'use strict';

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Transaction Factory
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++

angular
.module('sf_app')
.factory('TransactionFactory', TransactionFactory);

TransactionFactory.$inject = ['$state', '$location', '$http', 'apiBaseUrl', 'http_config'];
function TransactionFactory($state, $location, $http, apiBaseUrl, http_config) {
  let remove = function(id) {
    let transactionUrl = apiBaseUrl + '/transaction/remove/' + id;
    return $http.get(transactionUrl, http_config).then(
      function(response) { // If Success:
        if(response.status != 200 || response.data.success != true) {
          return $location.path('/error');
        }
        return response.data.result;
      },
      function(data) { //If Error:
        if(data.status == 401) {
          return $location.path('/login');
        } else {
          return $location.path('/error');
        }
      }
    );
  };
  let refund = function(id) {
    let transactionUrl = apiBaseUrl + '/transaction/refund/' + id;
    return $http.get(transactionUrl, http_config).then(
      function(response) { // If Success:
        if(response.status != 200 || response.data.success != true) {
          return $location.path('/error');
        }
        return response.data.result;
      },
      function(data) { //If Error:
        if(data.status == 401) {
          return $location.path('/login');
        } else {
          return $location.path('/error');
        }
      }
    );
  };
  let getPaymentErrorData = function(select) {
    let selectStudio = select.studio.id;
    let selectMonth = select.year.id + '-' + ('0' + select.month.id).slice(-2);
    let transactionUrl = apiBaseUrl + '/invoice/payment_error/' + selectStudio + '/' + selectMonth;
    return $http.get(transactionUrl, http_config).then(
      function(response) { // If Success:
        if(response.status != 200 || response.data.success != true) {
          return $location.path('/error');
        }
        return response.data.result;
      },
      function(data) { //If Error:
        if(data.status == 401) {
          return $location.path('/login');
        } else {
          return $location.path('/error');
        }
      }
    );
  };
  let downloadedReceipt = function(json) {
    let transactionUrl = apiBaseUrl + '/transaction/receipt';
    return $http.post(transactionUrl, json, http_config).then(
      function(response) { // If Success:
        if(response.status != 200 || response.data.success != true) {
          return $location.path('/error');
        }
        return response.data.result;
      },
      function(data) { //If Error:
        if(data.status == 401) {
          return $location.path('/login');
        } else {
          return $location.path('/error');
        }
      }
    );
  };
  return {
    remove: remove,
    refund: refund,
    getPaymentErrorData: getPaymentErrorData,
    downloadedReceipt: downloadedReceipt,
  };
}

},{}],15:[function(require,module,exports){
var ua = window.navigator.userAgent.toLowerCase();
var ver = window.navigator.appVersion.toLowerCase();
 
var isMSIE = (ua.indexOf('msie') > -1) && (ua.indexOf('opera') == -1);
var isIE6 = isMSIE && (ver.indexOf('msie 6.') > -1);
var isIE7 = isMSIE && (ver.indexOf('msie 7.') > -1);
var isIE8 = isMSIE && (ver.indexOf('msie 8.') > -1);
var isIE9 = isMSIE && (ver.indexOf('msie 9.') > -1);
var isIE10 = isMSIE && (ver.indexOf('msie 10.') > -1);
var isIE11 = (ua.indexOf('trident/7') > -1);
var isIE = isMSIE || isIE11;
var isEdge = (ua.indexOf('edge') > -1);
 
var isChrome = (ua.indexOf('chrome') > -1) && (ua.indexOf('edge') == -1);
var isFirefox = (ua.indexOf('firefox') > -1);
var isSafari = (ua.indexOf('safari') > -1) && (ua.indexOf('chrome') == -1);
var isOpera = (ua.indexOf('opera') > -1);
 
var isiPhone = (ua.indexOf('iphone') > -1);
var isiPad = (ua.indexOf('ipad') > -1);
var isAndroid = (ua.indexOf('android') > -1) && (ua.indexOf('mobile') > -1);
var isAndroidTablet = (ua.indexOf('android') > -1) && (ua.indexOf('mobile') == -1);
 
// For browser support
if(isIE || isEdge) {
  setTimeout("redirect_now()", 0);
  function redirect_now(){
    location.href='/redirect.html';
  }
}

// For maintenance
//setTimeout("maintenance_now()", 0);
function maintenance_now(){
  location.href='/maintenance.html';
}
},{}]},{},[1,8,15,2,3,4,5,6,7,9,10,11,12,13,14]);
